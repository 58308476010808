import SplashStyles from "../SplashPage/SplashStyles";
import { Route, Routes } from "react-router-dom";
import { verifyEncoding } from "../../actions/index";
import LoginModal from "../../components/Login/Login";
import SignUpModal from "../../components/Login/Signup/Signup";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import Xureal from "../../assets/images/homepage/xureal_logo.png";
import InfoBoxLogo from "../../assets/images/infoboxLogoBeta.png";
import LinkedIn from "../../assets/icons/socials/linkedin.svg";
import Instagram from "../../assets/icons/socials/instagram.svg";
import Discord from "../../assets/icons/socials/discord.svg";

const Auth = () => {
  const theme: any = useTheme();
  const accessToken = new URLSearchParams(window.location.search).get("access");
  const isSmallScreen = useMediaQuery(`(${theme.breakpoints.sm})`);

  const urlParams = new URLSearchParams(window.location.search);
  const encodedEmail = urlParams.get("user_invite");
  const waitlistEmail = encodedEmail
    ? JSON.parse(window.atob(encodedEmail)).email
    : "";

    const handleLegalLink = (url: string) => {
      window.open(url, '_blank')
    };

  return (
    <>
      <SplashStyles.Container>
        <div>
        <SplashStyles.HomeHeader style={{ justifyContent: "center" }}>
          <SplashStyles.HomeLogo src={InfoBoxLogo} />
        </SplashStyles.HomeHeader>
        <SplashStyles.HomeTitle style={{ fontSize: "48px" }}>
          Your Knowledge Powered by AI
        </SplashStyles.HomeTitle>
        </div>
        {waitlistEmail && (
          <SplashStyles.HomeSubtitle
            style={{ margin: "0 !important", maxWidth: "unset" }}
          >
            Please sign up using {waitlistEmail}
          </SplashStyles.HomeSubtitle>
        )}

        <Routes>
          <Route path="/" element={<LoginModal />} />
          <Route path="login" element={<LoginModal />} />
          <Route path="signup" element={<SignUpModal />} />
        </Routes>

        <SplashStyles.HomeFooter>
        {isSmallScreen && (
          <SplashStyles.FlexColumn
            style={{
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={Xureal}
              style={{ width: "100%", maxWidth: "248px" }}
              alt="Powered by Xureal"
            />
            <SplashStyles.FlexColumn $alignItems="flex-start">
              <a href="https://infobox.ai/about/" target="_blank">
                <SplashStyles.TextLink>About Infobox</SplashStyles.TextLink>
              </a>
              <a href="https://xureal.com" target="_blank">
                <SplashStyles.TextLink>Company</SplashStyles.TextLink>
              </a>
              <a href="mailto: marketing@infobox.ai">
                <SplashStyles.TextLink>Contact Us</SplashStyles.TextLink>
              </a>
              <SplashStyles.TextLink
                onClick={() =>
                  handleLegalLink(
                    "https://legal.infobox.ai/infoboxai-privacy-policy.html"
                  )
                }
              >
                Privacy Policy
              </SplashStyles.TextLink>
              <SplashStyles.TextLink
                onClick={() =>
                  handleLegalLink(
                    "https://legal.infobox.ai/infoboxai-terms-and-conditions.html"
                  )
                }
              >
                Terms of Service
              </SplashStyles.TextLink>
            </SplashStyles.FlexColumn>
            <div
              style={{
                height: "1px",
                width: "100%",
                backgroundColor: "#f9f9f9",
              }}
            />
            <SplashStyles.FlexColumn style={{ justifyContent: "center" }}>
              <SplashStyles.FlexRow $margin="24px 0">
                {/* <SplashStyles.SocialLInk src={Facebook} alt="Facebook link" /> */}
                <a
                  href="https://www.instagram.com/infobox.ai/"
                  target="_blank"
                >
                  <SplashStyles.SocialLInk
                    src={Instagram}
                    alt="Instagram link"
                  />
                </a>

                <a
                  href="https://www.linkedin.com/company/xureal-metaverse"
                  target="_blank"
                >
                  <SplashStyles.SocialLInk src={LinkedIn} alt="LinkedIn link" />
                </a>
                <a href="https://discord.gg/W2YsUjMX" target="_blank">
                  <SplashStyles.SocialLInk src={Discord} alt="Discord link" />
                </a>
              </SplashStyles.FlexRow>
              <SplashStyles.LoveMessage>
                MADE WITH ❤️ IN ORLANDO
              </SplashStyles.LoveMessage>
              <SplashStyles.LegalFooter
                style={{ marginRight: isSmallScreen ? "0" : "4vw" }}
              >
                &copy; 2023 All Rights Reserved. Xureal &reg;
              </SplashStyles.LegalFooter>
            </SplashStyles.FlexColumn>
          </SplashStyles.FlexColumn>
        )}
        {!isSmallScreen && (
          <>
            <SplashStyles.FlexRow
              $flex="1"
              $justifyContent="space-between"
              style={{ borderBottom: "2px solid #F9F9F9" }}
            >
              <img
                src={Xureal}
                style={{ width: "100%", maxWidth: "248px" }}
                alt="Powered by Xureal"
              />
              <SplashStyles.FooterColumn $setWidth="20%">
                <a href="https://infobox.ai/about/">
                  <SplashStyles.TextLink>About Infobox</SplashStyles.TextLink>
                </a>
                <a href="https://xureal.com" target="_blank">
                  <SplashStyles.TextLink>Company</SplashStyles.TextLink>
                </a>
                <a href="mailto: marketing@infobox.ai">
                  <SplashStyles.TextLink>Contact Us</SplashStyles.TextLink>
                </a>
              </SplashStyles.FooterColumn>
              <SplashStyles.FooterColumn $setWidth="20%">
                <SplashStyles.TextLink
                  onClick={() =>
                    handleLegalLink(
                      "https://legal.infobox.ai/infoboxai-privacy-policy.html"
                    )
                  }
                >
                  Privacy Policy
                </SplashStyles.TextLink>
                <SplashStyles.TextLink
                  onClick={() =>
                    handleLegalLink(
                      "https://legal.infobox.ai/infoboxai-terms-and-conditions.html"
                    )
                  }
                >
                  Terms of Service
                </SplashStyles.TextLink>
              </SplashStyles.FooterColumn>
            </SplashStyles.FlexRow>
            <SplashStyles.FlexRow
              $height="108px"
              $width="100%"
              $justifyContent="space-between"
            >
              <SplashStyles.FlexRow $justifyContent="flex-start" style={{ width: '20%' }} >
                {/* <SplashStyles.SocialLInk src={Facebook} alt="Facebook link" /> */}
                <a
                  href="https://www.instagram.com/infobox.ai/"
                  target="_blank"
                >
                <SplashStyles.SocialLInk src={Instagram} alt="Instagram link" />
                </a>
                <a
                  href="https://www.linkedin.com/company/xureal-metaverse"
                  target="_blank"
                >
                  <SplashStyles.SocialLInk src={LinkedIn} alt="LinkedIn link" />
                </a>
                <a href="https://discord.gg/W2YsUjMX" target="_blank">
                  <SplashStyles.SocialLInk src={Discord} alt="Discord link" />
                </a>
              </SplashStyles.FlexRow>
              <SplashStyles.LoveMessage style={{ width: '20%' }}>
                MADE WITH ❤️ IN ORLANDO
              </SplashStyles.LoveMessage>
              <SplashStyles.LegalFooter style={{ width: '20%' }}>
                &copy; 2023 All Rights Reserved. Xureal &reg;
              </SplashStyles.LegalFooter>
            </SplashStyles.FlexRow>
          </>
        )}
        <div style={{ width: "169px" }} />
      </SplashStyles.HomeFooter>

      </SplashStyles.Container>
    </>
  );
};

export default Auth;
