import { EmbeddingStyles } from "./AddWebsiteFiles.styles";
import { Dialog, useMediaQuery } from "@mui/material";
import { faXmark, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";
import { useClient } from "../../context/ClientContext";
import useDrivePicker from "react-google-drive-picker";
import { analyzePage } from "../../actions";
import { useState } from "react";
import LayoutStyles from "../../App.styles";
import WebsiteIcon from "../../assets/icons/website.png";
import {
  faArrowRightLong,
  faChevronLeft,
} from "@fortawesome/pro-solid-svg-icons";
import { LoadingAnimation } from "../../pages/SplashPage/SplashPage";
import UrlMenu from "../UrlMenu";

const AddWebsiteFiles = ({
  opened,
  setOpened,
  importFiles,
}: {
  opened: "" | "website" | "google-drive" | "one-drive" | "file-upload";
  setOpened: React.Dispatch<
    React.SetStateAction<
      "" | "website" | "google-drive" | "one-drive" | "file-upload"
    >
  >;
  importFiles: () => Promise<void>;
}) => {
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.sm);
  const { selectedSources, setSelectedSources } = useClient();
  const [showUrls, setShowUrls] = useState(false);
  const [website, setWebsite] = useState("");
  const [analyzedUrls, setAnalyzedUrls] = useState<string[]>([]);
  const [, setCurrentStep] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const uploadPages = () => {
    setOpened("");
    importFiles();
  };

  const handleAnalyze = () => {
    setLoading(true);
    let formattedUrl = website.startsWith("http")
      ? website
      : "https://" + website;
    setWebsite(formattedUrl);
    analyzePage({ url: formattedUrl })
      .then((res: { result: string; data: string[] }) => {
        if (res.result === "SUCCESS") {
          setAnalyzedUrls(res.data);
          setShowUrls(true);
          setLoading(false);
        }
      })
      .catch(() => {
        alert("Error Analyzing website, try again later");
        setLoading(false);
      });
  };

  return (
    <Dialog
      fullScreen={isSmallScreen}
      open={opened === "website"}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: isSmallScreen ? "0" : "34px",
          width: isSmallScreen ? "100vw" : "fit-content",
          height: isSmallScreen ? "100vh" : "fit-content",
          maxHeight: "100%",
          maxWidth: "100%",
        },
      }}
    >
      <EmbeddingStyles.Container>
        <EmbeddingStyles.CloseButton
          onClick={() => {
            setOpened("");
            setSelectedSources([]);
          }}
        >
          <FontAwesomeIcon
            icon={faXmark}
            color={"#C9B8AC"}
            style={{ height: 25 }}
          />
        </EmbeddingStyles.CloseButton>
        {showUrls && (
          <EmbeddingStyles.BackButton
            onClick={() => {
              setShowUrls(false);
              setSelectedSources([]);
            }}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              color={"#7689FF"}
              style={{ height: 13, marginRight: 4 }}
            />
            Back
          </EmbeddingStyles.BackButton>
        )}
        {!showUrls && (
          <EmbeddingStyles.TopSection>
            <img src={WebsiteIcon} alt="Google Drive" style={{ height: 46 }} />
            <EmbeddingStyles.Title>Import Website Data</EmbeddingStyles.Title>
          </EmbeddingStyles.TopSection>
        )}

        {/* <EmbeddingStyles.ScriptSection>
          {embedding}
        </EmbeddingStyles.ScriptSection> */}

        {!showUrls && !isLoading && (
          <LayoutStyles.LargeTextField
            placeholder="Enter your website"
            onChange={(event) => setWebsite(event.target.value)}
            InputProps={{
              endAdornment: (
                <LayoutStyles.LargeTextFieldButton
                  onClick={() => handleAnalyze()}
                  $enabled={!!website}
                  disabled={!website}
                >
                  <FontAwesomeIcon
                    icon={faArrowRightLong}
                    color="#FFF6F0"
                    size="2x"
                  />
                </LayoutStyles.LargeTextFieldButton>
              ),
            }}
          />
        )}
        {isLoading && <LoadingAnimation />}
        {showUrls && (
          <UrlMenu
            urls={[website.replace(/\/?$/, "/"), ...analyzedUrls].filter(
              (value, index, self) => self.indexOf(value) === index
            )}
            setSelectedUrls={setSelectedSources}
            selectedUrls={selectedSources}
            website={website}
            showContinue={false}
          />
        )}
        <EmbeddingStyles.ButtonArea>
          {showUrls && (
            <EmbeddingStyles.PreviewButton
              onClick={async () => {
                uploadPages();
                setSelectedSources([]);
              }}
            >
              <span style={{ marginLeft: 13 }}>Import Files</span>
              <FontAwesomeIcon
                style={{ height: 30 }}
                icon={faArrowCircleRight}
              />
            </EmbeddingStyles.PreviewButton>
          )}
        </EmbeddingStyles.ButtonArea>
      </EmbeddingStyles.Container>
    </Dialog>
  );
};

export default AddWebsiteFiles;
