import styled from "styled-components";
import { Dialog, Button } from "@mui/material";

const UploaderStyles = {
  Container: styled(Dialog)`
    & .MuiPaper-root {
        height: 100%;
        width: 100%;
        max-width: 798px;
        max-height: 471px;
        background-color: rgba(255, 255, 255);
        box-shadow: 0 2px 37px 0 rgba(41, 51, 107, 0.18);
        border: 2px solid #7689ff;
        border-radius: 34px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 47px;
        @media ${props => props.theme.breakpoints.sm} {
          max-height: unset;
          max-width: unset;
          justify-content: center;
          border-radius: 0;
        }
      }
    `,
  UploadOverlay: styled.div`
    position: relative;
    height: 250px;
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f9f9f9;
    border: 1px #a4b8ff dashed;
    border-radius: 12px;
    pointer-events: auto;
  `,
  ErrorMessage: styled.div`
  font-size: 12px;
    color: red;
    text-align: left;
    width: 100%;
    max-width: 400px;
    margin-bottom: 33px;
  `,
  ImageCancel: styled.div`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    top: 5px;
    right: 5px;
    border: 1px #777e91 solid;
    cursor: pointer;
  `,
  CustomIcon: styled.img`
    height: 100%;
    width: 100%;
    border-radius: 12px;
    object-fit: contain;
  `,
  UploadIcon: styled.img`
    width: 24px;
    height: auto;
  `,
  BrowseButton: styled(Button).attrs({ component: 'label'})`
  && {
    cursor: pointer;
    border: none;
    box-shadow: none;
    font-weight: 500;
    text-transform: none;
    font-family: inherit;
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary.main};
  }
    
  `,
  ModalCloseButton: styled.button`
    position: absolute;
    top: 10px;
    right: 20px;
    border: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    pointer-events: auto;
  `,
  Submitbutton: styled.button<{ $enabled: boolean }>`
      width: 100%;
      max-width: 230px;
      height: 48px;
      box-shadow: none;
      display: flex;
      background-color: ${(props) =>
        props.$enabled ? props.theme.colors.primary.main : "#E7E2DF" };
      font-size: 18px;
      font-weight: 400;
      font-family: inherit;
      color: #fff;
      margin: 0 auto;
      border: none;
      border-radius: 43.5px;
      align-items: center;
      justify-content: space-between;
      padding: 0 9.5px 0 15px;
      cursor: pointer;
    `,
};

export default UploaderStyles;
