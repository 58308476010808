import { useEffect, useState } from "react";
import LayoutStyles from "../App.styles";
import Header from "../components/Header";
import LibraryBody from "../components/LibraryBody/LibraryBody";
import Feedback from "../components/FeedbackModal/Feedback";
import { useClient } from "../context/ClientContext";
import { Integrations, useLibrary } from "../context/LibraryContext";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import CustomizerModal from "../components/DashboardModals/CustomizerModal";
import { useTheme } from "styled-components";
import chatbotBg from "../assets/images/chatbot-bg.png";
// Assets
import Google from "../assets/icons/googledocs.png";
// import OneDrive from "../assets/icons/onedrive.png";
import Website from "../assets/icons/website.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpFromLine } from "@fortawesome/pro-solid-svg-icons";
import FooterSection from "../components/Footer";
import Tooltip from "@mui/material/Tooltip";
import { motion } from "framer-motion";
import DeleteData from "../components/DeleteDataModal/DeleteData";
const InfoBoxLayout = () => {
  const { LibraryPage } = LayoutStyles;
  const navigate = useNavigate();
  const { initialized, authorized, currentChatbot, sources, isFeedbackOpen, isDeleteModalOpen } = useClient();
  const { activeLibPage, setLibraryPage, intDisplayData, isCustomOpen } = useLibrary();
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.sm);
  const [chatHover, setChatHover] = useState(false);
  const [showChatToolTip, setShowChatToolTip] = useState(false);
  useEffect(() => {
    if (!authorized) {
      navigate("/auth", { replace: true });
    }
  }, [initialized]);

  const handleChange = (newValue: Integrations) => {
    setLibraryPage(newValue);
  };

  return (
    <LibraryPage.Body>
      <Header />

      <motion.div
        onMouseOver={() => {
          setChatHover(true);
          setShowChatToolTip(!sources.every((source) => source.upload_status.type === "completed" || source.upload_status.type === "failed"));
        }}
        onMouseOut={() => {
          setChatHover(false);
          setShowChatToolTip(false);
        }}
      >
        <Tooltip title={"Your assistant is getting trained"} arrow open={showChatToolTip} placement="top">
          <LibraryPage.ChatbotButton
            $bgImage={chatbotBg}
            animate={!chatHover ? { scale: [1, 1.2, 1, 1.2, 1] } : undefined}
            transition={
              !chatHover
                ? {
                    repeat: Infinity,
                    repeatDelay: 60,
                    delay: 2,
                  }
                : undefined
            }
            whileHover={{ scale: chatHover ? 1.1 : 0 }}
            onMouseOver={() => {
              setChatHover(true);
              setShowChatToolTip(sources.every((source) => source.upload_status.type === "queued" || source.upload_status.type === "in_progress"));
            }}
            whileTap={{ scale: showChatToolTip ? 1.1 : 1 }}
            onMouseOut={() => {
              setChatHover(false);
              setShowChatToolTip(false);
            }}
            onClick={() => {
              // if (
              //   !sources.every(
              //     (source) => source.upload_status.type === "completed"
              //   )
              // )
              //   return;
              if (!showChatToolTip)
                !isSmallScreen
                  ? window.open(`${process.env.REACT_APP_BOT_URL}ai-fullpage-chat/?client=${currentChatbot.chatbotID}`, "_blank")
                  : window.open(`${process.env.REACT_APP_BOT_URL}ai-chatbot/?client=${currentChatbot.chatbotID}&open=true&showShare=true`, "_blank");
            }}
          >
            {currentChatbot?.widget_logo ? (
              <img
                src={currentChatbot?.widget_logo}
                alt=""
                style={{
                  height: 70,
                  width: 70,
                  borderRadius: "50%",
                }}
              />
            ) : (
              <LayoutStyles.Header.BotIcon style={{ height: 70, width: 70, fontSize: 34, marginRight: 0 }}>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {currentChatbot?.assistant_name[0]}
                </span>
              </LayoutStyles.Header.BotIcon>
            )}
          </LibraryPage.ChatbotButton>
        </Tooltip>
      </motion.div>

      <LibraryPage.SubMenu>
        <LayoutStyles.FlexRow style={{ height: "53px" }}>
          <LibraryPage.SubTitle>Knowledge Library</LibraryPage.SubTitle>
          {!isSmallScreen && (
            <>
              <LibraryPage.SubLink
                $active={activeLibPage === Integrations.All}
                onClick={() => setLibraryPage(Integrations.All)}
                animate={{
                  fontWeight: activeLibPage === Integrations.All ? 500 : 300,
                }}
              >
                All sources
                {activeLibPage === Integrations.All && <LibraryPage.BottomSelector layoutId="selected123" />}
              </LibraryPage.SubLink>
              <LibraryPage.SubLink
                $active={activeLibPage === Integrations.Website}
                onClick={() => setLibraryPage(Integrations.Website)}
                animate={{
                  fontWeight: activeLibPage === Integrations.Website ? 500 : 300,
                }}
              >
                <LibraryPage.SubIcon src={Website} />
                {intDisplayData[Integrations.Website].tabLabel}
                {activeLibPage === Integrations.Website && <LibraryPage.BottomSelector layoutId="selected123" />}
              </LibraryPage.SubLink>
              <LibraryPage.SubLink
                onClick={() => setLibraryPage(Integrations.Google)}
                $active={activeLibPage === Integrations.Google}
                animate={{
                  fontWeight: activeLibPage === Integrations.Google ? 500 : 300,
                }}
              >
                <LibraryPage.SubIcon src={Google} />
                {intDisplayData[Integrations.Google].tabLabel}
                {activeLibPage === Integrations.Google && <LibraryPage.BottomSelector layoutId="selected123" />}
              </LibraryPage.SubLink>
              <LibraryPage.SubLink
                $active={activeLibPage === Integrations.Uploads}
                onClick={() => setLibraryPage(Integrations.Uploads)}
                animate={{
                  fontWeight: activeLibPage === Integrations.Uploads ? 500 : 300,
                }}
              >
                <FontAwesomeIcon icon={faUpFromLine} style={{ marginRight: 11, height: 20, color: "#7689FF" }} />
                Uploads
                {activeLibPage === Integrations.Uploads && <LibraryPage.BottomSelector layoutId="selected123" />}
              </LibraryPage.SubLink>
            </>
          )}
          {isSmallScreen && (
            <>
              <LibraryPage.SourceSelect onChange={(e) => handleChange(e.target.value as Integrations)} defaultValue={Integrations.All}>
                <LibraryPage.SourceMenuItem value={Integrations.All}>All sources</LibraryPage.SourceMenuItem>
                <LibraryPage.SourceMenuItem value={Integrations.Website}>
                  <LibraryPage.SubIcon src={Website} alt="Display website sources" />
                  Website
                </LibraryPage.SourceMenuItem>
                <LibraryPage.SourceMenuItem value={Integrations.Google}>
                  <LibraryPage.SubIcon src={Google} alt="Display Google sources" />
                  Google Drive
                </LibraryPage.SourceMenuItem>
                <LibraryPage.SourceMenuItem value={Integrations.Uploads}>
                  <FontAwesomeIcon
                    icon={faUpFromLine}
                    style={{
                      marginRight: 15,
                      marginLeft: 3,
                      height: 20,
                      color: "#7689FF",
                    }}
                  />
                  Uploads
                </LibraryPage.SourceMenuItem>
              </LibraryPage.SourceSelect>
            </>
          )}
          {/* <LibraryPage.SubLink
            onClick={() => setLibraryPage(Integrations.OneDrive)}
            $active={activeLibPage === Integrations.OneDrive}
          >
            <LibraryPage.SubIcon src={OneDrive} />
            {intDisplayData[Integrations.OneDrive].tabLabel}
          </LibraryPage.SubLink> */}
        </LayoutStyles.FlexRow>
        {/* {<LayoutStyles.SearchBar
          placeholder="Search the entire knowledge base…"
          InputProps={{
            startAdornment: (
              <LayoutStyles.SearchIcon icon={faMagnifyingGlass} />
            ),
          }}
        />} */}
      </LibraryPage.SubMenu>
      <LibraryBody />
      {isCustomOpen && <CustomizerModal />}
      {isFeedbackOpen && <Feedback />}
      {isDeleteModalOpen && <DeleteData />}
      <FooterSection />
    </LibraryPage.Body>
  );
};

export default InfoBoxLayout;
