import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { loginUser, initJWT, getUserData } from "../../actions";
import logoIcon from "../../assets/images/branding/infobox/logoIcon.png";
import Google from "../../assets/images/branding/google/Google.png";
import { LoginStyles } from "./LoginStyles";
import { Checkbox } from "@mui/material";
import {
  faEye,
  faEyeSlash,
  faArrowCircleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { CurrentUser } from "../../types/AuthTypes";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClient } from "../../context/ClientContext";
import EmailIcon from "../../assets/icons/email.png";
import SplashStyles from "../../pages/SplashPage/SplashStyles";
import ForgotPasswordModal from "./ForgotPassword/ForgotPasswordModal";

const validate = (values: { email: string; password: string }) => {
  const errors: any = {};
  if (!values.email) {
    errors.email = "This field is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "This field is required";
  } else if (values.password.length < 5) {
    errors.password = "Password must be longer than 5 characters";
  }
  return errors;
};

const LoginModal = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailSelected, setEmailSelected] = useState(false);
  const [waitlistError, setWaitlistError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { authorized, currentUser, setLoginOpen, setSignupOpen, setForgotOpen, infoboxaiBETA, setAuthorized, setJsonWebToken, setCurrentUser, isForgotOpen } =
    useClient();
  const waitlistActive = process.env.REACT_APP_WAITLIST;
  const navigate = useNavigate();

  const handleShowPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const checkWaitlist = (email: string) =>
    infoboxaiBETA.getSubcriberByEmail(email);

  const handleForgotClick = () => {
    setLoginOpen(false);
    setForgotOpen(true);
  };

  useEffect(() => {
    if (authorized && currentUser.userID) {
      const isOnboarded = currentUser.onboarded;
      navigate(isOnboarded ? "/data" : "/create", { replace: true })
    }
  }, [authorized, currentUser])

  useEffect(() => {
    setTimeout(() => {
      setWaitlistError('');
    }, 3000)
  }, [waitlistError])

  const handleLogin = async (values: {
    email: string;
    password: string;
    agreement: boolean;
  }) => {
    // The call to check the user database will go here. Upon successful return
    // they will be logged in with the loginUser call and rerouted to the app
    
    if (waitlistActive) {
      await checkWaitlist(formik.values.email).then((res: any) => {
        console.log("waitlist", res);
        // if the user is not on the waitlist, return and prevent the remainder of the function from progressing
        if (res.result === "FAILED") {
          setWaitlistError(`You're not on our waitlist. Join now!`);
          return;
        }
        if (res.result === "SUCCESS" && !res.content.whitelisted) {
          setWaitlistError(`You're on the waitlist. We'll contact you soon!`);
        }
      });
    }
    loginUser({
      email: values.email,
      password: values.password,
    })
      .then((res: any) => {
        console.log("login res", res);
        let accessToken = res.ENCODING;
        if (res.result === "FAILED" && res.error === 'password is incorrect') {
          setPasswordError('Incorrect password')
        }
        if (res.result === "SUCCESS") {
          localStorage.setItem("JWT_TOKEN", res.JWT_TOKEN);
          localStorage.setItem("REFRESH_TOKEN", res.REFRESH_TOKEN);
          if (accessToken) {
                  initJWT(res.JWT_TOKEN).then(() => {
                    setJsonWebToken(res.JWT_TOKEN);
                    setAuthorized(true);
                    getUserData().then((res: any) => {
                      const isOnboarded = res.content.onboarded;
                      setCurrentUser(res.content as CurrentUser);
                      navigate(isOnboarded ? "/data" : "/create", { replace: true });
                    });
                  });
        }}
      })
      .catch((error: Error) => console.log("login error", error));
  };

  const formik = useFormik({
    initialValues: {
      // validateonmount: true,
      email: "",
      password: "",
      agreement: false,
    },
    validate,
    onSubmit: (values, props) => {
      handleLogin(values);
      props.setSubmitting(false);
    },
  });

  return (
    <LoginStyles.Container>
      <img
        style={isEmailSelected ? { marginBottom: "15px" } : {}}
        className="logo"
        src={logoIcon}
        alt="logo-icon"
      />

      {waitlistError && (
        <LoginStyles.WaitlistError
          style={isEmailSelected ? { marginBottom: "30px" } : {}}
        >
          {waitlistError}
        </LoginStyles.WaitlistError>
      )}
      {!isEmailSelected && (
        <SplashStyles.FlexColumn
          $justifyContent="space-between"
          style={{ minHeight: "250px" }}
        >
          <h1 style={isEmailSelected ? { marginBottom: "30px" } : {}}>
            Log into your account
          </h1>
          <div className="button-container">
            <button
              className="auth-buttons"
              onClick={() => {
                const popupWindow = window.open(
                  `${
                    process.env.REACT_APP_INFOBOX_USERS
                  }/google_auth/authorize/?env=${
                    window.location.origin.includes("localhost:")
                      ? "localhost"
                      : process.env.REACT_APP_ENVIRONMENT
                  }`,
                  "_blank",
                  "width=500,height=600,toolbar=no"
                );
                const handleMessage = (event: MessageEvent<any>) => {
                  const message = event.data;
                  if (message && event.source === popupWindow) {
                    console.log(message);
                    let res = JSON.parse(message);
                    console.log('parse res', res)
                    if (res.result === "SUCCESS" && localStorage.getItem('JWT_TOKEN')) {
                      console.log('got here')
                      initJWT(localStorage.getItem('JWT_TOKEN') as string)
                        .then(() => {
                          setJsonWebToken(localStorage.getItem('JWT_TOKEN') as string);
                          getUserData().then((res: any) => {
                            console.log("get user data", res);
                            const isOnboarded = res.content.onboarded;
                            setCurrentUser(res.content as CurrentUser);
                            setAuthorized(true);
                            navigate(isOnboarded ? "/data" : "/create", {
                              replace: true,
                            });
                          });
                          console.log("INIT JWT");
                        })
                        .catch((err: any) => console.error(err));
                      console.log(
                        "google",
                        `${process.env.REACT_APP_REDIRECT}/?access=${message}`
                      );
                    } else {
                      //err
                    }
                  }
                };
                window.addEventListener("message", handleMessage);
              }}
            >
              <img className="auth-icons" src={Google} alt="" />
              <div>Continue with Google</div>
            </button>
          </div>

          <div className="line-break">
            <hr />
            <div>or</div>
          </div>
          <div style={{ marginTop: "0" }} className="button-container">
            <button
              className="auth-buttons"
              onClick={() => {
                setEmailSelected(true);
              }}
            >
              <img className="auth-icons" src={EmailIcon} alt="" />
              <div>Sign in with Email</div>
            </button>
          </div>
        </SplashStyles.FlexColumn>
      )}
      {isEmailSelected && (
        <>
          <SplashStyles.BackIcon>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={() => setEmailSelected(false)}
              icon={faArrowLeftLong}
              size="xl"
            />
          </SplashStyles.BackIcon>
          <form onSubmit={formik.handleSubmit}>
            <h1 style={isEmailSelected ? { marginBottom: "30px" } : {}}>
              Log into your account
            </h1>
            <LoginStyles.TextFieldWrapper>
              <LoginStyles.TextField
                placeholder="Email"
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="errors">{formik.errors.email}</div>
              )}
            </LoginStyles.TextFieldWrapper>
            <LoginStyles.TextFieldWrapper>
              <LoginStyles.TextField
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                InputProps={{
                  endAdornment: showPassword ? (
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleShowPassword}
                      icon={faEyeSlash}
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleShowPassword}
                      icon={faEye}
                    />
                  ),
                }}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="errors">{formik.errors.password}</div>
              )}
              {passwordError && (
                <div className="errors">{passwordError}</div>
              )}
            </LoginStyles.TextFieldWrapper>
            <div className="remember-forgot">
              <label htmlFor="remember-me" className="remember-me">
                <Checkbox
                  name="agreement"
                  checked={formik.values.agreement}
                  onChange={formik.handleChange}
                />
                Remember Me
              </label>
              <LoginStyles.ColoredLink onClick={handleForgotClick}>
                Forgot Password?
              </LoginStyles.ColoredLink>
            </div>
            <LoginStyles.SubmitButton
              type="submit"
              onClick={() => handleLogin(formik.values)}
              disabled={!formik.isValid && formik.dirty}
              $isValid={formik.isValid}
              $dirty={formik.dirty}
            >
              <div>Login</div>
              <FontAwesomeIcon icon={faArrowCircleRight} size="xl" />
            </LoginStyles.SubmitButton>
          </form>
        </>
      )}
      {/* <span>
          New to Infobox.ai?{" "}
          <LoginStyles.ColoredLink onClick={handleBetaSignUp}>
            Join our waitlist
          </LoginStyles.ColoredLink>  
        </span> */}
      <SplashStyles.FlexColumn>
        <LoginStyles.Subtext>
          New to Infobox?
          <span
            onClick={() => {
              if (!process.env.REACT_APP_REDIRECT) return;
              window.location.href = process.env.REACT_APP_REDIRECT;
            }}
          >
            {" "}
            Join the waitlist
          </span>
        </LoginStyles.Subtext>
      </SplashStyles.FlexColumn>
    {isForgotOpen && <ForgotPasswordModal />}
    </LoginStyles.Container>
  );
};

export default LoginModal;
