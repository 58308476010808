import { useState, useEffect } from "react";
import LayoutStyles from "../../../App.styles";
import GoogleIcon from "../../../assets/icons/googledocs.png";
import WebsiteIcon from "../../../assets/icons/website.png";
import { Skeleton } from "@mui/material";
import DocCard from "../../Cards/DocCard";
import { SourceType } from "../../../types/AuthTypes";
import { dateToUtcNumber } from "../../../helpers/dateConversion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowUp,
  faUpFromLine,
} from "@fortawesome/pro-solid-svg-icons";
import { useClient } from "../../../context/ClientContext";
import AddGoogleFiles from "../../AddGoogleFiles/AddGoogleFiles";
import AddWebsiteFiles from "../../AddWebsiteFiles/AddWebsiteFiles";
import AddLocalFiles from "../../AddLocalFiles/AddLocalFiles";

const AllSources = ({
  sources,
  importFiles,
  deleteSource,
}: {
  sources: SourceType[];
  importFiles: () => Promise<void>;
  deleteSource: (sourceID: string) => void;
}) => {
  const { LibrarySection } = LayoutStyles;
  const { loading } = useClient();
  const [uploadOpen, setUploadOpen] = useState<
    "website" | "google-drive" | "one-drive" | "file-upload" | ""
  >("");

  const googleSources = sources.filter(
    (source) => source.sourceType === "google-drive"
  );
  const websiteSources = sources.filter(
    (source) => source.sourceType === "website"
  );

  const uploadSources = sources.filter(
    (source) => source.sourceType === "file-upload"
  );

  return (
    <>
      <LibrarySection.DocumentSection>
        <LibrarySection.TitleSection>
          <LibrarySection.LibraryTitle>
            <LibrarySection.IntegrationIcon src={GoogleIcon} />
            Google Drive
          </LibrarySection.LibraryTitle>
          <LibrarySection.UploadButton
            onClick={() => {
              setUploadOpen("");
              setUploadOpen("google-drive");
              console.log("BUTTON PUSH");
            }}
          >
            <FontAwesomeIcon icon={faCircleArrowUp} style={{ height: 18 }} />
            Upload files
          </LibrarySection.UploadButton>
          <AddGoogleFiles
            opened={uploadOpen}
            setOpened={setUploadOpen}
            importFiles={importFiles}
          />
        </LibrarySection.TitleSection>
        <LibrarySection.SubTitle
          style={{
            marginBottom: 15,
            display: googleSources.length ? "block" : "none",
          }}
        >
          Recently uploaded files
        </LibrarySection.SubTitle>
        {!loading ? (
          <LibrarySection.DocumentRow container>
            {sources
              .filter((source) => source.sourceType === "google-drive")
              .reverse()
              .slice(0, 6)
              .map((source, index) => (
                <DocCard
                  name={source.name}
                  progress={source.progress}
                  uploaded={source.uploaded}
                  lastEdited={dateToUtcNumber(source.last_updated)}
                  key={source.sourceID}
                  fileType={source.fileType}
                  source={source}
                  deleteSource={deleteSource}
                  synced={source.synced}
                />
              ))}
          </LibrarySection.DocumentRow>
        ) : (
          <LibrarySection.DocumentRow container>
            {Array(4)
              .fill("")
              .map((_, index) => (
                <Skeleton key={index}>
                  <div
                    style={{ minHeight: 73, width: 195, borderRadius: 14 }}
                  />
                </Skeleton>
              ))}
          </LibrarySection.DocumentRow>
        )}
      </LibrarySection.DocumentSection>
      {/* <LibrarySection.DocumentSection>
        <LibrarySection.LibraryTitle>
          <LibrarySection.IntegrationIcon src={OneDrive} />
          OneDrive
        </LibrarySection.LibraryTitle>
        <LibrarySection.DocumentRow container>
          {sources
            .filter((source) => source.sourceType === "one-drive")
            .map((source, index) => (
              <DocCard
                name={source.name}
                lastEdited={dateToUtcNumber(source.last_updated)}
                key={index}
                fileType={source.fileType}
              />
            ))}
        </LibrarySection.DocumentRow>
      </LibrarySection.DocumentSection> */}
      <LibrarySection.DocumentSection>
        <LibrarySection.TitleSection>
          <LibrarySection.LibraryTitle>
            <LibrarySection.IntegrationIcon src={WebsiteIcon} />
            Website
          </LibrarySection.LibraryTitle>
          <LibrarySection.UploadButton onClick={() => setUploadOpen("website")}>
            <FontAwesomeIcon icon={faCircleArrowUp} style={{ height: 18 }} />
            Import your website content
          </LibrarySection.UploadButton>

          {uploadOpen === "website" && (
            <AddWebsiteFiles
              opened={uploadOpen}
              setOpened={setUploadOpen}
              importFiles={importFiles}
            />
          )}
        </LibrarySection.TitleSection>
        <LibrarySection.SubTitle
          style={{
            marginBottom: 15,
            display: websiteSources.length ? "block" : "none",
          }}
        >
          Recently imported URLs
        </LibrarySection.SubTitle>
        {!loading ? (
          <LibrarySection.DocumentRow container>
            {sources
              .filter((source) => source.sourceType === "website")
              .reverse()
              .slice(0, 6)
              .map((source, index) => (
                <DocCard
                  name={source.name}
                  progress={source.progress}
                  uploaded={source.uploaded}
                  lastEdited={dateToUtcNumber(source.last_updated)}
                  key={source.sourceID}
                  fileType={source.fileType}
                  source={source}
                  deleteSource={deleteSource}
                  synced={source.synced}
                />
              ))}
          </LibrarySection.DocumentRow>
        ) : (
          <LibrarySection.DocumentRow container>
            {Array(4)
              .fill("")
              .map((_, index) => (
                <Skeleton key={index}>
                  <div
                    style={{ minHeight: 73, width: 195, borderRadius: 14 }}
                  />
                </Skeleton>
              ))}
          </LibrarySection.DocumentRow>
        )}
      </LibrarySection.DocumentSection>
      <LibrarySection.DocumentSection>
        <LibrarySection.TitleSection>
          <LibrarySection.LibraryTitle>
            <FontAwesomeIcon
              icon={faUpFromLine}
              style={{ marginRight: 13, color: "#7689FF" }}
            />
            Uploads
          </LibrarySection.LibraryTitle>
          <LibrarySection.UploadButton
            onClick={() => {
              setUploadOpen("");
              setUploadOpen("file-upload");
              console.log("BUTTON PUSH");
            }}
          >
            <FontAwesomeIcon icon={faCircleArrowUp} style={{ height: 18 }} />
            Upload files
          </LibrarySection.UploadButton>
          <AddLocalFiles
            opened={uploadOpen}
            setOpened={setUploadOpen}
            importFiles={importFiles}
          />
        </LibrarySection.TitleSection>
        <LibrarySection.SubTitle
          style={{
            marginBottom: 15,
            display: uploadSources.length ? "block" : "none",
          }}
        >
          Recently uploaded files
        </LibrarySection.SubTitle>
        {!loading ? (
          <LibrarySection.DocumentRow container>
            {sources
              .filter((source) => source.sourceType === "file-upload")
              .reverse()
              .slice(0, 6)
              .map((source, index) => (
                <DocCard
                  name={source.name}
                  progress={source.progress}
                  uploaded={source.uploaded}
                  lastEdited={dateToUtcNumber(source.last_updated)}
                  key={source.sourceID}
                  fileType={source.fileType}
                  source={source}
                  deleteSource={deleteSource}
                  synced={source.synced}
                />
              ))}
          </LibrarySection.DocumentRow>
        ) : (
          <LibrarySection.DocumentRow container>
            {Array(4)
              .fill("")
              .map((_, index) => (
                <Skeleton key={index}>
                  <div
                    style={{ minHeight: 73, width: 195, borderRadius: 14 }}
                  />
                </Skeleton>
              ))}
          </LibrarySection.DocumentRow>
        )}
      </LibrarySection.DocumentSection>
    </>
  );
};

export default AllSources;
