import {useEffect} from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { useClient } from "./context/ClientContext";


const Layout = () => {
  const { isSignupOpen, isLoginOpen, authorized } = useClient();

  useEffect(() => {
    console.log('authorized', authorized)
  }, [authorized])


  return (
    <>
      <BrowserRouter basename="/">
        <Router />
      </BrowserRouter>
    </>
  );
};

export default Layout;
