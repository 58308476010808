import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import GoogleRedirect from "./pages/GoogleRedirect";
import GoogleDriveAuth from "./pages/GoogleDriveAuth";
import GoogleDriveRedirect from "./pages/GoogleDriveRedirect";
import Auth from "./pages/Auth/Auth";
import Logout from "./pages/Logout";
import { useClient } from "./context/ClientContext";
import InfoBoxLayout from "./pages/InfoBoxLayout";
import CreatePage from "./pages/Create";
import PreviewPage from "./pages/Preview/PreviewPage";
import PasswordReset from "./pages/PasswordReset/PasswordReset";

const Router = () => {
  const { authorized, initialized, currentUser } = useClient();
  const urlParams = new URLSearchParams(window.location.search);
  const isPasswordReset = window.location.href.includes("reset_password");
  const encodedEmail = urlParams.get("user_invite");
  const navigate = useNavigate();

  useEffect(() => {
    if (!authorized && initialized && !encodedEmail && !isPasswordReset) {
      navigate("/auth/login");
    }
  }, [initialized, authorized]);

  return (
    <>
      <Routes>
        <Route path="/auth/*" element={<Auth />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/reset_password" element={<PasswordReset />} />

        {/* Google Paths  */}
        <Route path="/google-redirect" element={<GoogleRedirect />} />
        <Route path="/google-drive-auth" element={<GoogleDriveAuth />} />
        <Route
          path="/google-drive-redirect"
          element={<GoogleDriveRedirect />}
        />

        {/* Microsoft Paths  */}
        <Route path="/authorize" element={<></>} /> 
        {/* Protected routes below */}
        {authorized && (
          <>
            <Route path="/data" element={<InfoBoxLayout />} />
            <Route path="/preview" element={<PreviewPage />} />
            <Route path="/create" element={<CreatePage />} />
            <Route path="*" element={<Navigate to="/data" />} />
          </>
        )}
        <Route path="/" element={<Auth />} />
      </Routes>
    </>
  );
};

export default Router;
