import React, { useState, useEffect } from "react";
import LayoutStyles from "../../App.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faBrush,
  faPaperPlane,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import DefaultLogo from "../../assets/images/default-logo.png";
import { ChromePicker, ColorResult } from "react-color";
import { useClient } from "../../context/ClientContext";
import MediaUploader from "../FileUploader/MediaUploader";
import { useTheme } from "styled-components";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

export type CustomStyles = {
  logo: string;
  avatar: string;
  color: string;
}

type Props = {
  selectedUrls: {
    name: string;
    sourceType: string;
    sourceData: { [key: string]: any };
  }[];
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  customStyles: CustomStyles;
  setCustomStyles: React.Dispatch<React.SetStateAction<CustomStyles>>;
};

const Customizer: React.FC<Props> = ({ setCustomStyles, setCurrentStep, customStyles }) => {
  const { OBCustomizer } = LayoutStyles;
  const [colorOpen, setColorOpen] = useState(false);
  const navigate = useNavigate();
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [previewTextColor, setPreviewText] = useState("#ffffff");
  const { companyName } = useClient();
  const [currentImgProp, setImgProp] = useState("");
  const theme: any = useTheme();
  
  const useMobileLayout = useMediaQuery(theme.breakpoints.sm);
  

  const handleColorSelect = (color: ColorResult) => {
    setCustomStyles((prev) => ({ ...prev, color: color.hex }));
    setPreviewText(getTextColor(color.hex));
  };

  const getTextColor = (backgroundColor: string) => {
    const color = backgroundColor.substring(1);
    console.log("color", color);
    // Example logic for determining contrasting text color based on background color
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness >= 180 ? "#000000" : "#ffffff";
  };

  return (
    <>
      <OBCustomizer.Container>
        <OBCustomizer.Preview>
          <OBCustomizer.PreviewBG>
            <OBCustomizer.ChatBox>
              <OBCustomizer.TopBar $bgColor={customStyles.color}>
                <OBCustomizer.ImageContainer
                  $size="39"
                  $bgImage={customStyles.logo ? customStyles.logo : DefaultLogo}
                />
                <OBCustomizer.ChatTitle $color={previewTextColor}>
                  {companyName ? companyName : "Test company name"}
                </OBCustomizer.ChatTitle>
              </OBCustomizer.TopBar>
              <OBCustomizer.ChatSection>
                <OBCustomizer.ChatBubble>
                  {`Type a message to begin your conversation with ${companyName}.`}
                  <OBCustomizer.ExampleAvatar
                    $bgImage={
                      customStyles.avatar ? customStyles.avatar : DefaultLogo
                    }
                  />
                </OBCustomizer.ChatBubble>
                <LayoutStyles.FlexRow>
                  <div
                    style={{
                      flex: "1",
                      height: useMobileLayout ? "35px" : "41px",
                      borderRadius: "10px",
                      backgroundColor: "#F3F4F9",
                    }}
                  />
                  <OBCustomizer.SendButton $bgColor={customStyles.color}>
                    <FontAwesomeIcon
                      size="lg"
                      icon={faPaperPlane}
                      color={previewTextColor}
                    />
                  </OBCustomizer.SendButton>
                </LayoutStyles.FlexRow>
              </OBCustomizer.ChatSection>
            </OBCustomizer.ChatBox>
            <OBCustomizer.ChatButton
              $bgImage={customStyles.logo ? customStyles.logo : DefaultLogo}
            />
          </OBCustomizer.PreviewBG>
        </OBCustomizer.Preview>
        <OBCustomizer.OptionContainer>
          <OBCustomizer.OptionBox
            onClick={() => {
              setImgProp("logo");
              setUploaderOpen(true);
            }}
          >
            <OBCustomizer.ImageContainer
              $bgImage={customStyles.logo ? customStyles.logo : DefaultLogo}
            />
            <OBCustomizer.Label>Assistant logo</OBCustomizer.Label>
            <OBCustomizer.IconContainer>
              <FontAwesomeIcon icon={faArrowCircleUp} size="lg" color="#fff" />
            </OBCustomizer.IconContainer>
          </OBCustomizer.OptionBox>
          <OBCustomizer.OptionBox
            onClick={() => {
              setImgProp("avatar");
              setUploaderOpen(true);
            }}
          >
            <OBCustomizer.ImageContainer
              $bgImage={customStyles.avatar ? customStyles.avatar : DefaultLogo}
            />
            <OBCustomizer.Label>Assistant avatar</OBCustomizer.Label>
            <OBCustomizer.IconContainer>
              <FontAwesomeIcon icon={faArrowCircleUp} size="lg" color="#fff" />
            </OBCustomizer.IconContainer>
          </OBCustomizer.OptionBox>
          <OBCustomizer.OptionBox onClick={() => setColorOpen(true)}>
            <div style={{ width: "50px" }}>
              <OBCustomizer.CurrentColor $bgColor={customStyles.color} />
            </div>
            <OBCustomizer.Label>
              Assistant color
              <OBCustomizer.SubText>{customStyles.color}</OBCustomizer.SubText>
            </OBCustomizer.Label>
            <OBCustomizer.IconContainer>
              <FontAwesomeIcon icon={faBrush} size="lg" color="#fff" />
            </OBCustomizer.IconContainer>
            {colorOpen && (
              <div style={{ position: "absolute", right: "0", top: "0" }}>
                <div
                  style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setColorOpen(false);
                  }}
                />
                <ChromePicker
                  color={customStyles.color}
                  onChangeComplete={handleColorSelect}
                  disableAlpha
                />
              </div>
            )}
          </OBCustomizer.OptionBox>
        </OBCustomizer.OptionContainer>
      </OBCustomizer.Container>

      <LayoutStyles.CreatePage.ContinueButton
        onClick={async () => {
          setCurrentStep(3);
        }}
        $enabled={true}
      >
        Continue
        <FontAwesomeIcon size="xl" icon={faArrowCircleRight} />
      </LayoutStyles.CreatePage.ContinueButton>

      <MediaUploader
        open={uploaderOpen}
        setUploaderOpen={setUploaderOpen}
        setCustomStyles={setCustomStyles}
        property={currentImgProp}
      />
    </>
  );
};

export default Customizer;
