import { useEffect } from "react";
import { getGoogleAccess } from "../actions";

const GoogleRedirect = () => {

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // Get the value of the 'code' parameter
    const code = urlParams.get("code");
    console.log(code);
    if (code) {
      getGoogleAccess({ accessToken: code }).then(
        (res: { result: string; JWT_TOKEN: string; REFRESH_TOKEN: string }) => {
          console.log(res);
          if (res.result === "SUCCESS") {
            localStorage.setItem("JWT_TOKEN", res.JWT_TOKEN);
            localStorage.setItem("REFRESH_TOKEN", res.REFRESH_TOKEN);
          }
          window.opener.postMessage(
            JSON.stringify({ result: res.result }),
            "*"
          );
          window.close();
        }
      );
    }
  }, []);
  return <></>;
};

export default GoogleRedirect;
