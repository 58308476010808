import { useClient } from "../../context/ClientContext";
import useDrivePicker from "react-google-drive-picker";
import { getUserGoogleDrive } from "../../actions";
import { useEffect, useRef, useState } from "react";

const AddGoogleFiles = ({
  opened,
  setOpened,
  importFiles,
}: {
  opened: "" | "website" | "google-drive" | "one-drive" | "file-upload";
  setOpened: React.Dispatch<
    React.SetStateAction<
      "" | "website" | "google-drive" | "one-drive" | "file-upload"
    >
  >;
  importFiles: () => Promise<void>;
}) => {
  const [openPicker] = useDrivePicker();
  const { setGoogleDriveConnected, selectedSources, setSelectedSources } =
    useClient();
  const [token, setToken] = useState("");
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        opened === "google-drive" &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpened("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (opened === "google-drive")
      getUserGoogleDrive()
        .then((res: any) => {
          if (res.result === "SUCCESS") {
            setToken(res.token);
          }
        })
        .catch((err) => {
          console.log(err);
          const popupWindow = window.open(
            `${process.env.REACT_APP_BASE_URL}/google-drive-auth`,
            "_blank",
            "width=500,height=600,toolbar=no"
          );

          const handleMessage = (event: MessageEvent<any>) => {
            const message = event.data;
            if (message && event.source === popupWindow) {
              console.log(message);
              let res = JSON.parse(message);
              if (res.result === "SUCCESS") {
                setGoogleDriveConnected(true);
                getDriveToken();
              } else {
                setGoogleDriveConnected(false);
              }
            }
          };
          window.addEventListener("message", handleMessage);
        });
  }, [opened]);

  const getDriveToken = () => {
    getUserGoogleDrive().then((res: any) => {
      if (res.result === "SUCCESS") {
        setToken(res.token);
      }
    });
  };
  const mimeTypes = [
    "application/vnd.google-apps.presentation",
    "application/vnd.google-apps.document",
    "application/vnd.google-apps.presentation",
    "application/vnd.google-apps.file",
    "application/msword",
    "text/plain",
    "text/vtt",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
  ];

  const openGooglePicker = () => {
    openPicker({
      clientId:
        "499706963417-t5qaqd6tbrup95oq22ef45ec70j38tou.apps.googleusercontent.com",
      developerKey: "AIzaSyAKpHq2je-sWn6pNPJbBdWeBcnIQ93Qgi8",
      viewId: "DOCS",
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      setIncludeFolders: true,
      setSelectFolderEnabled: false,
      viewMimeTypes: mimeTypes.reduce((a, b) => a + "," + b),
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        if (data.action === "loaded") {
        }
        if (data.action === "cancel") {
          setOpened("");
        }
        if (Array.isArray(data.docs)) {
          setSelectedSources((prev) => {
            let objects = [...data.docs];
            const idTracker: { [id: string]: boolean } = {};
            const result = [];
            for (let i = 0; i < objects.length; i++) {
              const currentObject = objects[i];
              const id = currentObject.id;
              let fileType = "";
              if (currentObject.mimeType.includes("pdf")) {
                fileType = "pdf";
              } else if (currentObject.mimeType.includes("presentation")) {
                fileType = "slide";
              } else if (currentObject.mimeType.includes("document")) {
                fileType = "doc";
              } else if (currentObject.mimeType.includes("text")) {
                fileType = "text";
              } else if (currentObject.mimeType.includes("folder")) {
                fileType = "folder";
              }
              let objCopy = currentObject as any;
              objCopy.fileType = fileType;
              // if (!prev.find((element) => element?.sourceData?.id === id)) {
              //   idTracker[id] = true;
              result.push({
                name: currentObject.name,
                sourceType: "google-drive",
                sourceData: objCopy,
              });
              // }
            }
            return [...prev, ...result];
          });
        }
      },
    });
  };

  useEffect(() => {
    if (opened === "google-drive" && token) {
      openGooglePicker();
    }
  }, [opened, token]);

  useEffect(() => {
    if (selectedSources.length > 0 && opened === "google-drive") {
      const importData = async () => {
        await importFiles();
        setSelectedSources([]);
        setOpened("");
      };

      importData();
    }
  }, [selectedSources]);

  return (
    // <Dialog
    //   fullScreen={isSmallScreen}
    //   open={!!opened}
    //   sx={{
    //     "& .MuiPaper-root": {
    //       borderRadius: isSmallScreen ? "0" : "34px",
    //       width: "fit-content",
    //       height: "fit-content",
    //       maxHeight: "100%",
    //       maxWidth: "100%",
    //     },
    //   }}
    // >
    //   <EmbeddingStyles.Container>
    //     <EmbeddingStyles.CloseButton
    //       onClick={() => {
    //         setOpened("");
    //         setSelectedSources([]);
    //       }}
    //     >
    //       <FontAwesomeIcon
    //         icon={faXmark}
    //         color={"#C9B8AC"}
    //         style={{ height: 25 }}
    //       />
    //     </EmbeddingStyles.CloseButton>
    //     <EmbeddingStyles.TopSection>
    //       <img src={DriveIcon} alt="Google Drive" style={{ height: 46 }} />
    //       <EmbeddingStyles.Title>
    //         Add Files from Google Drive
    //       </EmbeddingStyles.Title>
    //       <EmbeddingStyles.SubText onClick={openGooglePicker}>
    //         Add more files...
    //       </EmbeddingStyles.SubText>
    //     </EmbeddingStyles.TopSection>

    //     {/* <EmbeddingStyles.ScriptSection>
    //       {embedding}
    //     </EmbeddingStyles.ScriptSection> */}

    //     <EmbeddingStyles.FileSection>
    //       <LayoutStyles.CreatePage.DocumentGrid
    //         rowGap={"14px"}
    //         style={{ marginBottom: 60, justifyContent: "center" }}
    //       >
    //         {selectedSources
    //           .filter((source) => source.sourceType === "google-drive")
    //           .map((source, index) => (
    //             <LayoutStyles.CreatePage.GridItem
    //               xs={12}
    //               sm={6}
    //               md={3}
    //               key={index}
    //             >
    //               <div
    //                 style={{ position: "relative" }}
    //                 onMouseOver={() => setHoverIdx(index)}
    //                 onMouseOut={() => setHoverIdx(-1)}
    //               >
    //                 {index === hoverIdx && (
    //                   <LayoutStyles.CreatePage.DeleteButton
    //                     onClick={() => {
    //                       setSelectedSources((prev) =>
    //                         prev.filter((_, idx) => idx !== index)
    //                       );
    //                     }}
    //                   >
    //                     <FontAwesomeIcon icon={faXmark} />
    //                   </LayoutStyles.CreatePage.DeleteButton>
    //                 )}
    //                 <DocCard
    //                   name={source.name}
    //                   lastEdited={source.sourceData?.lastEditedUtc as number}
    //                   fileType={source.sourceData.fileType}
    //                   uploaded={true}
    //                 />
    //               </div>
    //             </LayoutStyles.CreatePage.GridItem>
    //           ))}
    //       </LayoutStyles.CreatePage.DocumentGrid>
    //     </EmbeddingStyles.FileSection>
    //     <EmbeddingStyles.ButtonArea>
    //       <EmbeddingStyles.PreviewButton
    //         onClick={async () => {
    //           await importFiles();
    //           setOpened("");
    //           setSelectedSources([]);
    //         }}
    //       >
    //         <span style={{ marginLeft: 13 }}>Import Files</span>
    //         <FontAwesomeIcon style={{ height: 30 }} icon={faArrowCircleRight} />
    //       </EmbeddingStyles.PreviewButton>
    //     </EmbeddingStyles.ButtonArea>
    //   </EmbeddingStyles.Container>
    // </Dialog>
    <div ref={dropdownRef}></div>
  );
};

export default AddGoogleFiles;
