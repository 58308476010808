import React, { useEffect } from "react";
import FeedbackStyles from "./FeedbackStyles";
import FooterSection from "../Footer";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClient } from "../../context/ClientContext";

const Feedback = () => {
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.sm);
  const { setFeedbackOpen } = useClient();
  const formEl: HTMLElement | null = document.getElementById("my-reform");
  const formElHtml: string | undefined = formEl?.innerHTML;

  return (
    <FeedbackStyles.Dialog open={true}>
      <FontAwesomeIcon
        onClick={() => setFeedbackOpen(false)}
        icon={faXmark}
        size="xl"
        style={{
          position: "absolute",
          top: "15px",
          right: "20px",
          zIndex: "3",
          cursor: "pointer",
        }}
      />
      <FeedbackStyles.Container>
        {formElHtml && (
          <FeedbackStyles.Container
            dangerouslySetInnerHTML={{ __html: formElHtml }}
            id="custom-reform"
          />
        )}
      </FeedbackStyles.Container>
    </FeedbackStyles.Dialog>
  );
};

export default Feedback;
