import Layout from "./MainLayout";
import ClientProvider from "./context/ClientContext";
import Theme from "./theme";
import LibraryProvider from "./context/LibraryContext";

function App() {
  return (
    <ClientProvider>
      <LibraryProvider>
        <Theme>
          <Layout />
        </Theme>
      </LibraryProvider>
    </ClientProvider>
  );
}

export default App;
