import LayoutStyles from "../App.styles";
import { faBars, faPaintRoller, faGear, faXmark, faComment, faRightFromBracket, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faPenLine } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClient } from "../context/ClientContext";
import { customizeChatbot } from "../actions";
import { ChatbotType } from "../types/AuthTypes";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, List, ListItemText, Tooltip, InputAdornment } from "@mui/material";
import { useTheme } from "styled-components";
import { useLibrary } from "../context/LibraryContext";
import ChatBubble from "../assets/icons/chat-bubble.svg";
import LogOutIcon from "../assets/icons/log-out-arrow.svg";
import { useFormik } from "formik";

const Header = () => {
  const { Header, DocCard } = LayoutStyles;
  const { currentChatbot, setFeedbackOpen, setCurrentChatbot, setDeleteModalOpen } = useClient();
  const { setCustomOpen } = useLibrary();
  const [isMobileDrawerOpen, setMobileDrawer] = useState(false);
  const navigate = useNavigate();
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.sm);
  const [show, setShow] = useState(false);
  const [nameEditing, setNameEditing] = useState(false);
  const nameTextFieldRef = useRef<HTMLInputElement | null>(null);
  const nameAnchor = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleBotNameSubmit = () => {
    if (formik.values.assistantName === "") {
      setNameEditing(false);
      return;
    }
    customizeChatbot({
      assistant_name: formik.values.assistantName,
      chatbotID: currentChatbot.chatbotID,
    })
      .then((res: { result: string; data: ChatbotType }) => {
        setNameEditing(false);
        console.log("res", res);
        if (res.result === "SUCCESS") {
          setCurrentChatbot(res.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: {
      assistantName: currentChatbot?.assistant_name ? currentChatbot.assistant_name : "",
    },
    onSubmit: handleBotNameSubmit,
  });

  const [anchorPosition, setAnchorPosition] = useState<
    | {
        top: number;
        left: number;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    const handleClickOutside = async (event: MouseEvent) => {
      // Check if the clicked element is outside the TextField
      if (nameTextFieldRef.current && !nameTextFieldRef.current.contains(event.target as Node)) {
        formik.handleSubmit();
      } else {
        return;
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // if (!source) return;
    setAnchorEl(event.currentTarget);
    setShow(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    navigate("/logout");
  };

  const handleBotNameToggle = () => {
    setNameEditing(true);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        handleClose();
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [anchorEl, anchorPosition]);

  return (
    <Header.HeaderSection ref={nameAnchor}>
      <LayoutStyles.FlexRow style={{ alignItems: "center" }}>
        {currentChatbot?.widget_logo ? (
          <img
            src={currentChatbot?.widget_logo}
            alt=""
            style={{
              height: 45,
              width: 45,
              borderRadius: "50%",
              marginRight: "12px",
            }}
          />
        ) : (
          <Header.BotIcon>
            <span>{currentChatbot?.assistant_name[0]}</span>
          </Header.BotIcon>
        )}
        {!nameEditing && !isSmallScreen && (
          <Tooltip title="Edit assistant name">
            <Header.BotNameButton onClick={handleBotNameToggle}>
              <Header.BotName>{currentChatbot?.assistant_name}</Header.BotName>
            </Header.BotNameButton>
          </Tooltip>
        )}
        {nameEditing && !isSmallScreen && (
          <LayoutStyles.TextField
            ref={nameTextFieldRef}
            autoFocus
            id="assistantName"
            name="assistantName"
            value={formik.values.assistantName}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={formik.handleReset} icon={faXmark} size="lg" />,
            }}
          />
        )}
        {isSmallScreen && (
          <div
            onClick={() => setPopoverOpen(true)}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Header.BotName>{currentChatbot?.assistant_name}</Header.BotName>
            <FontAwesomeIcon icon={faPenLine} size="sm" />
          </div>
        )}
      </LayoutStyles.FlexRow>
      {!isSmallScreen && (
        <LayoutStyles.FlexRow>
          <LayoutStyles.LibraryPage.SubLink onClick={() => window.open(window.location.origin + "/preview", "_blank")} style={{ minWidth: 100 }}>
            Install Assistant
          </LayoutStyles.LibraryPage.SubLink>

          <LayoutStyles.LibraryPage.SubLink onClick={() => setCustomOpen(true)}>Customize</LayoutStyles.LibraryPage.SubLink>

          <DocCard.Wrapper
            onClick={handleClick}
            style={{
              cursor: "pointer",
            }}
          >
            Settings
          </DocCard.Wrapper>
          {/* <DocCard.Box> */}
          <DocCard.Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            autoFocus={false}
            style={{
              margin: "10px 0",
              left: "-70px",
              width: "25vw",
              padding: "10px",
            }}
          >
            <DocCard.MenuItem
              onClick={() => setDeleteModalOpen(true)}
              style={{
                width: "95%",
                height: "50%",
                padding: "10px",
                margin: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#7689FF",
                  height: "20px",
                  width: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{
                    height: "10px",
                    width: "10px",
                    color: "white",
                  }}
                />
              </div>
              Clear all training data
            </DocCard.MenuItem>
            <DocCard.MenuItem
              onClick={() => setFeedbackOpen(true)}
              style={{
                width: "95%",
                height: "50%",
                padding: "10px",
                margin: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#7689FF",
                  height: "20px",
                  width: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                }}
              >
                <img
                  src={ChatBubble}
                  alt="chat-bubble-icon"
                  style={{
                    height: "10px",
                    width: "10px",
                  }}
                />
              </div>
              Share your feedback
            </DocCard.MenuItem>
            <DocCard.MenuItem
              onClick={handleLogout}
              style={{
                width: "95%",
                height: "50%",
                padding: "10px",
                margin: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#7689FF",
                  height: "20px",
                  width: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                  // marginRight: '0'
                }}
              >
                <img
                  src={LogOutIcon}
                  alt="log-out-icon"
                  style={{
                    height: "10px",
                    width: "10px",
                    color: "white",
                  }}
                />
              </div>
              Log Out
            </DocCard.MenuItem>
          </DocCard.Menu>
          {/* </DocCard.Box> */}
          {/* <Header.TestButton
            $disabled={
              !sources.every(
                (source) => source.upload_status.type === "completed"
              )
            }
            onClick={() => {
              if (
                !sources.every(
                  (source) => source.upload_status.type === "completed"
                )
              )
                return;
              window.open(
                `${process.env.REACT_APP_BOT_URL}${
                  isSmallScreen ? "ai-chatbot" : "ai-fullpage-chat"
                }/?client=${currentChatbot.chatbotID}`,
                "_blank"
              );
            }}
          >
            Test your Assistant
          </Header.TestButton> */}
          {/* <LayoutStyles.LibraryPage.LogoutButton onClick={handleLogout}> */}
          {/* <SplashStyles.SignupButton
            onClick={handleLogout}
            style={{
              marginLeft: "15px",
              padding: "2px 9px",
              backgroundColor: "#A5B1FF",
              fontSize: "13px",
            }}
          >
            Log Out
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              size="sm"
              style={{
                marginLeft: "6px",
              }}
            />
          </SplashStyles.SignupButton> */}
          {/* </LayoutStyles.LibraryPage.LogoutButton> */}
        </LayoutStyles.FlexRow>
      )}
      {isSmallScreen && <FontAwesomeIcon onClick={() => setMobileDrawer(true)} icon={faBars} size="xl" />}
      <Header.MobileDrawer open={isMobileDrawerOpen} anchor="right">
        <LayoutStyles.FlexColumn>
          <LayoutStyles.FlexRow
            onClick={() => setMobileDrawer(false)}
            // $margin="0 16px 24px"
          ></LayoutStyles.FlexRow>
          <LayoutStyles.FlexRow
            // $margin="0 16px 24px"
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#EFDCDA",
              width: "100%",
              padding: "19px 30px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {currentChatbot?.widget_logo ? (
                <img
                  src={currentChatbot?.widget_logo}
                  alt=""
                  style={{
                    height: 31,
                    width: 31,
                    borderRadius: "50%",
                    marginRight: "12px",
                  }}
                />
              ) : (
                <Header.BotIcon>
                  <span>{currentChatbot?.assistant_name[0]}</span>
                </Header.BotIcon>
              )}
              <Header.BotName>{currentChatbot?.assistant_name}</Header.BotName>
            </div>

            <FontAwesomeIcon
              icon={faXmark}
              size="xl"
              onClick={() => {
                setMobileDrawer(false);
              }}
            />
          </LayoutStyles.FlexRow>
          <LayoutStyles.FlexRow>
            <List
              style={{
                padding: "10px",
              }}
            >
              <Header.MobileDrawerItem onClick={() => setCustomOpen(true)}>
                <Header.MobileDrawerIcon>
                  <FontAwesomeIcon icon={faPaintRoller} inverse size="1x" />
                </Header.MobileDrawerIcon>
                <ListItemText>Customize</ListItemText>
              </Header.MobileDrawerItem>
              <Header.MobileDrawerItem onClick={() => window.open(window.location.origin + "/preview", "_blank")}>
                <Header.MobileDrawerIcon>
                  <FontAwesomeIcon icon={faGear} inverse size="1x" />
                </Header.MobileDrawerIcon>
                <ListItemText>Install Assistant</ListItemText>
              </Header.MobileDrawerItem>
              <Header.MobileDrawerItem onClick={() => setDeleteModalOpen(true)}>
                <Header.MobileDrawerIcon>
                  <FontAwesomeIcon icon={faTrash} inverse size="1x" />
                </Header.MobileDrawerIcon>
                <ListItemText>Clear all training data</ListItemText>
              </Header.MobileDrawerItem>
              <Header.MobileDrawerItem onClick={() => setFeedbackOpen(true)}>
                <Header.MobileDrawerIcon>
                  <FontAwesomeIcon icon={faComment} inverse size="1x" />
                </Header.MobileDrawerIcon>
                <ListItemText>Share your feedback</ListItemText>
              </Header.MobileDrawerItem>
              <Header.MobileDrawerItem onClick={handleLogout}>
                <Header.MobileDrawerIcon>
                  <FontAwesomeIcon icon={faRightFromBracket} inverse size="1x" />
                </Header.MobileDrawerIcon>
                <ListItemText>Log out</ListItemText>
              </Header.MobileDrawerItem>
            </List>
          </LayoutStyles.FlexRow>
        </LayoutStyles.FlexColumn>
      </Header.MobileDrawer>
      {/* {scriptOpen && (
        <ChatBotScript opened={scriptOpen} setOpened={setScriptOpen} />
      )} */}
      <Header.BotNamePopover
        anchorEl={nameAnchor.current}
        open={popoverOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <LayoutStyles.FlexRow style={{ justifyContent: "space-between", marginBottom: "16px" }}>
          <p>Edit Name</p>
          <FontAwesomeIcon size="xl" onClick={() => setPopoverOpen(false)} icon={faXmark} />
        </LayoutStyles.FlexRow>
        <LayoutStyles.TextField
          sx={{
            "&&": {
              width: "100%",
              marginBottom: "24px",
            },
          }}
          id="assistantName"
          name="assistantName"
          value={formik.values.assistantName}
          onChange={formik.handleChange}
        />
        <LayoutStyles.FlexRow style={{ justifyContent: "space-between", marginBottom: "16px" }}>
          <Header.PopoverButton onClick={() => setPopoverOpen(false)} $bgColor="white" $color={theme.colors.primary.main}>
            Cancel
          </Header.PopoverButton>
          <Header.PopoverButton
            onClick={() => {
              handleBotNameSubmit();
              setPopoverOpen(false);
            }}
            $bgColor={theme.colors.primary.main}
            $color="white"
          >
            Save
          </Header.PopoverButton>
        </LayoutStyles.FlexRow>
      </Header.BotNamePopover>
    </Header.HeaderSection>
  );
};

export default Header;
