import styled from "styled-components";
import { Dialog } from "@mui/material";

const FeedbackStyles = {
  Dialog: styled(Dialog)`
    & .MuiPaper-root {
      height: 85%;
      min-width: 50%;
      max-width: 50%;
      border-radius: 30px;
      overflow: hidden;
      @media ${props => props.theme.breakpoints.md} {
        height: 100%;
        width: 100%;
        margin: 0;
        max-width: unset;
        max-height: unset;
        border-radius: 0;
      }
    }
  `,
  Container: styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  Iframe: styled.iframe`
    height: 3300px;
    width: 100%;
    @media ${props => props.theme.breakpoints.sm} {
        height: 3500px;
    }
  `,
};

export default FeedbackStyles;
