import betaLogo from "../assets/images/homepage/beta_logo.png";
import xurealLogo from "../assets/images/homepage/xureal_logo.png";

import LayoutStyles from "../App.styles";
const { Footer } = LayoutStyles;
const FooterSection = () => {
  return (
    <Footer.Container>
      <Footer.BetaLogo src={betaLogo} />
      <Footer.XurealLogo src={xurealLogo} />
    </Footer.Container>
  );
};

export default FooterSection;
