import { useState, useEffect } from "react";
import SignUpOptions from "./SignUpOptions";
import SignUpWithEmail from "./SignUpWithEmail";
import InfoBoxLogo from "../../../assets/images/infoboxLogoBeta.png"
import { LoginStyles } from "../LoginStyles";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import { useClient } from "../../../context/ClientContext";

const SignUpModal = () => {
  const [emailSignUpOpen, setOpenEmailSignUp] = useState(false);
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(`(${theme.breakpoints.sm})`);
  const { infoboxaiBETA } = useClient();
  const urlParams = new URLSearchParams(window.location.search);
  const encodedEmail = urlParams.get("user_invite");
  const waitlistEmail = encodedEmail
    ? JSON.parse(window.atob(encodedEmail)).email
    : "";

  const handleEmailClick = () => {
    setOpenEmailSignUp(true);
  };

  useEffect(() => {
    if (!encodedEmail && process.env.REACT_APP_REDIRECT) {
      window.location.href = process.env.REACT_APP_REDIRECT;
    }
  }, []);

  useEffect(() => {
    if (waitlistEmail && infoboxaiBETA) {
      infoboxaiBETA.updateSubscriberData({
        email: waitlistEmail,
        invite_clicked: true,
        updateType: "invite",
      });
    }
  }, [waitlistEmail, infoboxaiBETA]);

  if (!encodedEmail) {
    return <></>;
  }

  return (
    <LoginStyles.Container
      style={{ height: emailSignUpOpen ? "fit-content" : "504px" }}
    >
      {isSmallScreen && (
              <img className="logo" src={InfoBoxLogo} alt="logo-icon" />
            )}
      {!emailSignUpOpen ? (
        <SignUpOptions onEmailClick={handleEmailClick} />
      ) : (
        <SignUpWithEmail
          open={emailSignUpOpen}
          setOpenEmailSignUp={setOpenEmailSignUp}
          onLoginClick={() => {}}
        />
      )}
    </LoginStyles.Container>
  );
};

export default SignUpModal;
