import { useEffect, useState } from "react";
import { EmbeddingStyles } from "./ChatbotScript.styles";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "styled-components";
import { useClient } from "../../context/ClientContext";
import { faCopy, faThumbtack, faCheck } from "@fortawesome/pro-solid-svg-icons";
import LayoutStyles from "../../App.styles";

const ChatbotScript = () => {
  const { currentChatbot } = useClient();
  const [copied, setCopied] = useState(false);
  const [embedding, setEmbedding] = useState(``);

  useEffect(() => {
    if (!currentChatbot) return;
    console.log('getting here')
    setEmbedding(`
    <script src=
    "${process.env.REACT_APP_BOT_URL}embed/infobox-ai-bot.js"
    ></script>
    <script>
      setTimeout(() => {
          xurealAIButton.init(
           "${process.env.REACT_APP_BOT_URL}ai-chatbot/" +
           "?client=${currentChatbot?.chatbotID}",
              {
                right: "0px",
               bottom: "0px",
             }
          );
     }, 1e3);
    </script>
    `)
  }, [currentChatbot])

  const handleImageError = (event: any) => {
    const imgElement = event.target;
    imgElement.style.display = "none";
  };
  const handleCopyText = async () => {
    try {
      await navigator.clipboard.writeText(embedding);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1800);
    } catch (error) {
      console.error("Copy failed:", error);
    }
  };
  return (
    <EmbeddingStyles.Container>
      <LayoutStyles.Header.BotIcon style={{ margin: "0 0 9px 0" }}>
        <img
          src={
            currentChatbot?.widget_logo
              ? currentChatbot.widget_logo
              : currentChatbot.assistant_name[0]
          }
          alt=""
          style={{ height: "100%", width: "100%", borderRadius: "50%" }}
          onError={handleImageError}
        />
      </LayoutStyles.Header.BotIcon>
      <EmbeddingStyles.Title>
        Knowledge Assistant’s Script
      </EmbeddingStyles.Title>
      <EmbeddingStyles.SubText>
        <FontAwesomeIcon
          icon={faThumbtack}
          color={"#7689FF"}
          style={{ height: 15, marginRight: 10 }}
        />
        Copy the AI Assistant script and paste it directly into your website
        source code.
      </EmbeddingStyles.SubText>
      <EmbeddingStyles.ScriptSection>
        <div style={{overflow: "hidden"}} className="embed">{embedding}</div>
      </EmbeddingStyles.ScriptSection>
      <EmbeddingStyles.ButtonArea>
        <EmbeddingStyles.CopyButton onClick={handleCopyText}>
          {" "}
          {copied ? "Copied" : "Copy Script"}
        </EmbeddingStyles.CopyButton>
        {/* <EmbeddingStyles.PreviewButton
            onClick={() => {
              window.open(
                `https://ai-services.dev.xureal.com/infobox-fullpage/?client=${currentChatbot.chatbotID}`,
                "_blank"
              );
            }}
          >
            Preview Bot
          </EmbeddingStyles.PreviewButton> */}
      </EmbeddingStyles.ButtonArea>
    </EmbeddingStyles.Container>
  );
};

export default ChatbotScript;
