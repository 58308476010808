import Email from "../../../assets/icons/email.png";
import { useNavigate } from "react-router-dom";
import Google from "../../../assets/images/branding/google/Google.png";
import { LoginStyles } from "../LoginStyles";
import { useClient } from "../../../context/ClientContext";
import SplashStyles from "../../../pages/SplashPage/SplashStyles";
import { initJWT, getUserData } from "../../../actions";
import { CurrentUser } from "../../../types/AuthTypes";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";

const SignUpOptions: React.FC<{
  onEmailClick: () => void;
}> = ({ onEmailClick }) => {
  const { setAuthorized, setJsonWebToken, setCurrentUser } = useClient();
  const { SignUp } = LoginStyles;
  const navigate = useNavigate();
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.sm);

  return (
    <>
      {!isSmallScreen && <div className="copy">
        <h1>Sign up</h1>
      </div>}
      <div>
      {isSmallScreen && <div className="copy">
        <h1 style={{marginBottom: '30px'}}>Sign up</h1>
      </div>}
      <SignUp.ButtonsWrapper>
        <div className="button-container">
          <button
            className="auth-buttons"
            onClick={() => {
              const popupWindow = window.open(
                `${
                  process.env.REACT_APP_INFOBOX_USERS
                }/google_auth/authorize/?env=${
                  window.location.origin.includes("localhost:")
                    ? "localhost"
                    : process.env.REACT_APP_ENVIRONMENT
                }`,
                "_blank",
                "width=500,height=600,toolbar=no"
              );
              const handleMessage = (event: MessageEvent<any>) => {
                const message = event.data;
                if (message && event.source === popupWindow) {
                  console.log(message);
                  let res = JSON.parse(message);
                  if (
                    res.result === "SUCCESS" &&
                    localStorage.getItem("JWT_TOKEN")
                  ) {
                    let isOnboarded = false
                    initJWT(localStorage.getItem("JWT_TOKEN") as string)
                      .then(() => {
                        setJsonWebToken(
                          localStorage.getItem("JWT_TOKEN") as string
                        );
                        getUserData()
                          .then((res: any) => {
                            console.log("get user data", res);
                            isOnboarded = res.content.onboarded;
                            setCurrentUser(res.content as CurrentUser);
                            setAuthorized(true);
                          })
                          .then(() => {
                            navigate(isOnboarded ? "/data" : "/create", {
                              replace: true,
                            });
                          });
                        console.log("INIT JWT");
                      })
                      .catch((err: any) => console.error(err));
                    console.log(
                      "google",
                      `${process.env.REACT_APP_REDIRECT}/?access=${message}`
                    );
                  } else {
                    //err
                  }
                }
              };
              window.addEventListener("message", handleMessage);
            }}
          >
            <img className="auth-icons" src={Google} alt="" />
            <div>Sign up with Google</div>
          </button>
        </div>
        <div className="line-break">
          <hr />
          <div>or</div>
        </div>
        <div className="button-container">
          <button className="auth-buttons" onClick={onEmailClick}>
            <img className="auth-icons" src={Email} alt="" />
            <div>Sign up with email</div>
          </button>
        </div>
      </SignUp.ButtonsWrapper>
      </div>
      <SplashStyles.FlexColumn>
        <LoginStyles.Subtext>
          Can't sign up?<a href="mailto: marketing@infobox.ai"> Contact us</a>
        </LoginStyles.Subtext>
        <LoginStyles.Subtext>
          New to Infobox?
          <span
            onClick={() => {
              if (!process.env.REACT_APP_REDIRECT) return;
              window.location.href = process.env.REACT_APP_REDIRECT;
            }}
          >
            {" "}
            Join the waitlist
          </span>
        </LoginStyles.Subtext>
      </SplashStyles.FlexColumn>
    </>
  );
};

export default SignUpOptions;
