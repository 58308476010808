import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SplashStyles from "./SplashStyles";
import { faBars, faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import { motion, Variants } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import { useClient } from "../../context/ClientContext";

// Assets

import InfoBoxLogo from "../../assets/images/infoboxLogo.png";
import CenterUIImage from "../../assets/images/ui-graphic.png";
import UserYellow from "../../assets/images/homepage/user_yellow.png";
import UserLilac from "../../assets/images/homepage/user_lilac.png";
import UserBlush from "../../assets/images/homepage/user_blush.png";
import UrlIcon from "../../assets/images/homepage/url.png";
import WebIcon from "../../assets/images/homepage/web_icon.png";
import DocIcon from "../../assets/images/homepage/doc.png";
import GoogleIcon from "../../assets/images/branding/google/google_docs.png";
import AIIcon from "../../assets/images/homepage/ai-logo.png";

const SplashPage = () => {
  const navigate = useNavigate();
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(`(${theme.breakpoints.sm})`);
  const { setSignupOpen, setLoginOpen } = useClient();

  const handleOpenSignup = () => {
    setSignupOpen(true);
    setLoginOpen(false);
  }

  const handleOpenLogin = () => {
    setSignupOpen(false);
    setLoginOpen(true);
  }

  return (
    <SplashStyles.Container>
      <SplashStyles.FlexColumn
        style={{ width: "100%", maxWidth: "1440px", alignItems: "center" }}
      >
        <SplashStyles.HomeHeader>
          <div style={{ flex: "1", textAlign: "left" }}>
            <FontAwesomeIcon icon={faBars} size={isSmallScreen ? "xl" : "2x"} />
          </div>
          <SplashStyles.HomeLogo src={InfoBoxLogo} />
          <SplashStyles.FlexRow
            style={{ flex: "1", justifyContent: "flex-end" }}
          >
            {!isSmallScreen && (
              <SplashStyles.HeaderButton onClick={handleOpenSignup}>
                Sign up
              </SplashStyles.HeaderButton>
            )}
            <SplashStyles.HeaderButton onClick={handleOpenLogin} $bgColor="#EAECFF">
              Log in
            </SplashStyles.HeaderButton>
          </SplashStyles.FlexRow>
        </SplashStyles.HomeHeader>
        <SplashStyles.FlexColumn
          style={{ alignItems: "center", maxWidth: "80%" }}
        >
          <SplashStyles.HomeTitle>
            Your Website Powered by{" "}
            <SplashStyles.Highlight>AI</SplashStyles.Highlight>
          </SplashStyles.HomeTitle>
          <SplashStyles.HomeSubtitle>
            Add an intelligent{" "}
            <SplashStyles.Highlight>AI</SplashStyles.Highlight> layer on top of
            your website instantly.{" "}
          </SplashStyles.HomeSubtitle>
          <SplashStyles.SignupButton onClick={() => navigate("/create")}>
            <FontAwesomeIcon
              style={{ marginRight: "14px" }}
              icon={faRightToBracket}
            />
            Sign up free now
          </SplashStyles.SignupButton>
        </SplashStyles.FlexColumn>
      </SplashStyles.FlexColumn>

      <SplashStyles.ImageContainer>
        <div style={{ position: "relative", maxWidth: "741px", width: "80%" }}>
          <img
            style={{ width: "100%", height: "auto" }}
            src={CenterUIImage}
            alt="homepage graphic"
          />
          <SplashStyles.FloatingImage
            $width="5.5vw"
            $top="-3.5vh"
            $left="-50px"
            src={DocIcon}
            alt="Document Icon"
          />
          <SplashStyles.FloatingImage
            $width="6.5vw"
            $bottom="14.5vh"
            $right="-60px"
            src={WebIcon}
            alt="Web Icon"
          />
        </div>

        <SplashStyles.FloatingImage
          $width="5.5vw"
          $top="-9.5vh"
          $right="15vw"
          src={GoogleIcon}
          alt="Google Icon"
        />
        <SplashStyles.FloatingImage
          $width="6.5vw"
          $top="149px"
          $left="12vw"
          src={UserBlush}
          alt="User Icon"
        />
        <SplashStyles.FloatingImage
          $width="5.5vw"
          $top="13vh"
          $right="10vw"
          src={UserLilac}
          alt="User Icon"
        />
        <SplashStyles.FloatingImage
          $width="1.5vw"
          $minWidth="50px"
          $bottom="6.5vw"
          $right="9.5vw"
          src={UserYellow}
          alt="User Icon"
        />
        <SplashStyles.FloatingImage
          $width="6.4vw"
          $top="260px"
          $left="17vw"
          src={UrlIcon}
          alt="URL Icon"
        />
      </SplashStyles.ImageContainer>

      <SplashStyles.HomeFooter>
        {isSmallScreen && (
          <SplashStyles.FlexColumn>
            <SplashStyles.LegalFooter
              style={{ marginRight: isSmallScreen ? "0" : "4vw" }}
            >
              &copy; 2023 All Rights Reserved. Xureal &reg;
            </SplashStyles.LegalFooter>
            <SplashStyles.FlexRow style={{justifyContent: 'center'}}>
              <SplashStyles.LegalFooter
                style={{ marginRight: isSmallScreen ? "1vw" : "0" }}
              >
                Terms of Service
              </SplashStyles.LegalFooter>
              <SplashStyles.LegalFooter>
                Privacy Policy
              </SplashStyles.LegalFooter>
            </SplashStyles.FlexRow>
          </SplashStyles.FlexColumn>
        )}
        {!isSmallScreen && (
          <SplashStyles.FlexRow>
            <SplashStyles.LegalFooter style={{ marginRight: "4vw" }}>
              &copy; 2023 All Rights Reserved. Xureal &reg;
            </SplashStyles.LegalFooter>
            <SplashStyles.LegalFooter>
              Terms of Service
            </SplashStyles.LegalFooter>
            <SplashStyles.LegalFooter>Privacy Policy</SplashStyles.LegalFooter>
          </SplashStyles.FlexRow>
        )}
        <SplashStyles.AILogoContainer src={AIIcon} alt="AI" />
      </SplashStyles.HomeFooter>
    </SplashStyles.Container>
  );
};
type NavigationButtonsTypes = {
  handlePrevSlide: () => void;
  currentSlide: number;
  handleNextSlide: () => void;
  companyName: string;
  website: string;
};
const NavigationButtons = ({
  handlePrevSlide,
  currentSlide,
  handleNextSlide,
  companyName,
  website,
}: NavigationButtonsTypes) => {
  return <></>;
};
export const LoadingAnimation = () => {
  const loadingCircleStyle = {
    height: 10,
    width: 10,
    borderRadius: "50%",
    backgroundColor: "#b4c0ff",
  };
  const itemVariants: Variants = {
    loading: {
      transition: {
        duration: 0.7,
        ease: "easeInOut",
        repeat: Infinity,
        repeatDelay: 0.4,
      },
      y: [0, -5, 0],
    },
  };
  const listVariants: Variants = {
    loading: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <motion.div
      variants={listVariants}
      animate="loading"
      style={{ display: "flex", columnGap: 4 }}
    >
      <motion.div variants={itemVariants} style={loadingCircleStyle} />
      <motion.div variants={itemVariants} style={loadingCircleStyle} />
      <motion.div variants={itemVariants} style={loadingCircleStyle} />
    </motion.div>
  );
};

export default SplashPage;
