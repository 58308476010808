import React, { createContext, useContext, useState } from "react";
import { LibraryContext } from "../types/LibraryTypes";
import GoogleIcon from "../assets/icons/googledocs.png";
import OneDrive from "../assets/icons/onedrive.png";
import WebsiteIcon from "../assets/icons/website.png";

// This is not finished!! Don't look at it!

const LibraryCtx = createContext({});

export enum Integrations {
  All,
  Google,
  OneDrive,
  Website,
  Uploads,
}

export const intDisplayData = {
  [Integrations.All]: {
    tabLabel: "All sources",
    logo: "",
    id: "all",
  },
  [Integrations.Google]: {
    tabLabel: "Google Drive",
    logo: GoogleIcon,
    id: "google",
  },
  [Integrations.OneDrive]: {
    tabLabel: "OneDrive",
    logo: OneDrive,
    id: "oneDrive",
  },
  [Integrations.Website]: {
    tabLabel: "Website",
    logo: WebsiteIcon,
    id: "website",
  },
  [Integrations.Uploads]: {
    tabLabel: "Uploads",
    logo: WebsiteIcon,
    id: "uploads",
  },
};

const LibraryProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeLibPage, setLibraryPage] = useState<Integrations>(
    Integrations.All
  );
  const [isCustomOpen, setCustomOpen] = useState(false);
  const [libraryLoading, setLibraryLoading] = useState(false);

  // intialize the application and get the configuration data

  return (
    <LibraryCtx.Provider
      value={{
        libraryLoading,
        setLibraryLoading,
        setLibraryPage,
        activeLibPage,
        intDisplayData,
        setCustomOpen,
        isCustomOpen,
      }}
    >
      {children}
    </LibraryCtx.Provider>
  );
};

export const useLibrary = () => {
  return useContext(LibraryCtx) as LibraryContext;
};

export default LibraryProvider;
