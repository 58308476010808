import styled from "styled-components"; // Use this for regular element styling
import { Form } from "formik";

export const EmbeddingStyles = {
  Container: styled.div`
    font-family: "halyard-display";
    height: 657px;
    width: 942px;
    max-width: 100%;
    border: 1px solid #caafbb;
    position: relative;
    background: linear-gradient(223.31deg, #fff6f0 0%, #ffffff 100%);
    box-shadow: 0 2px 37px 0 rgba(41, 51, 107, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    justify-content: flex-start;
    padding: 57px;
    position: relative;
    @media (min-width: 351px) and (max-width: 768px) {
      height: 100vh;
    width: 100vw;
      .container {
        border: 0px transparent !important;
        box-shadow: none !important;
        background: white;
      }
      .close {
        display: none;
      }
    }
  `,
  ScriptSection: styled.pre`
    color: black;
    background-color: white;
    padding: 24.75px;
    height: fit-content;
    width: 554px;
    border: 1px solid #ece3de;
    opacity: 0.7;
    border-radius: 20.5px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 32px;
  `,
  FileSection: styled.div`
    overflow-y: auto;
    height: 300px;
    width: 100%;
  `,
  TopSection: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  `,
  Title: styled.div`
    color: #20243a;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 33px;
    text-align: center;
    margin-bottom: 10px;
  `,
  SubText: styled.div`
    color: #7689ff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    margin-bottom: 24px;
    max-width: 364px;
  `,
  ColoredLink: styled.a`
    color: #7689ff;
    font-weight: 500;
    cursor: pointer;
  `,
  TextFieldWrapper: styled.div`
    position: relative;
    margin-bottom: 8px;
    padding-bottom: 7px;
    width: 100%;
  `,

  Terms: styled.p`
    font-size: 12px;
    margin-bottom: 15px;
  `,

  ButtonArea: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
  `,

  PreviewButton: styled.button`
    background-color: #7689ff;
    height: 48px;
    width: 228.93px;
    border: 1px solid #7689ff;
    border-radius: 25px;
    color: #ffffff;
    font-size: 17px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  `,
  CopyButton: styled.button`
    color: #7689ff;
    height: 48px;
    width: 228.93px;
    border: 1px solid #7689ff;
    border-radius: 25px;
    font-size: 17px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    column-gap: 10px;
    cursor: pointer;
  `,
  CloseButton: styled.div`
    position: absolute;
    top: 32px;
    right: 48px;
    cursor: pointer;
  `,
  BackButton: styled.div`
    position: absolute;
    top: 32px;
    left: 48px;
    cursor: pointer;

    color: #7689ff;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
  `,
  Header: styled.h1`
    height: 41px;
    // width: 456px;
    color: #20243a;
    font-family: "Halyard Display";
    font-size: 30px;
    font-weight: 1;
    letter-spacing: 0;
    line-height: 33px;
    text-align: center;
    margin-bottom: 20px;
  `,

  PartialBorder: styled.div`
    display: inline;
    height: 1px;
    width: 40%;
    background-color: #e1e0e7;
    margin: 23px 0;
    flex: 1;
  `,

  Form: styled(Form)`
    width: 100%;
    max-width: 500px;
    height: 575px;
    background-color: plum;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `,

  TextFieldsContainer: styled.div<{
    mobileLayout?: boolean;
    $isSecondChild?: boolean;
  }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: ${(props) =>
      !props.$isSecondChild ? "0" : props.mobileLayout ? "0" : "20px"};
    margin-bottom: 30px;
    margin-top: ${(props) =>
      props.$isSecondChild && props.mobileLayout ? "16px" : "0"};
  `,
};
